import React, { Component } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload } from "@fortawesome/free-solid-svg-icons";

function Intro() {
  return (
    <section id="worum-gehts" data-scroll="intro" className="intro-container">
      <h1 className="intro-header">Worum geht's?</h1>
      <div className="womix__intro-text">
        <div className="inner-wrap">
          <div className="womix__video-wrapper">
            <div>
              {" "}
              <iframe
                src="https://player.vimeo.com/video/518551718?dnt=1"
                frameBorder="0"
                className=""
              ></iframe>{" "}
            </div>
          </div>
          <div className="text-wrapper">
            <p className="intro-desc">
              <strong>Du</strong> möchtest die{" "}
              <strong>Zukunft mitgestalten</strong> und verstehen, wie
              intelligente Algorithmen funktionieren? Du siehst in Künstlicher
              Intelligenz die Chance, Probleme zu lösen und die Welt zu
              verbessern? Dann melde dich{" "}
              <a
                href="https://ki-kurs.org/app/auth"
                target="_blank"
                className="verlinkung-intro"
              >
                hier
              </a>{" "}
              an, um die Grundlagen der KI zu erlernen!
              <br />
              <br />
              Dieser Kurs ist{" "}
              <strong>der ideale Einstieg für Interessierte</strong>, die
              verstehen und lernen wollen, wie KI funktioniert. Wir führen dich
              über die Grundlagen der Programmierung über einfache
              Klassifikatoren bis hin zu neuronalen Netzen. Du wirst sehen: KI
              ist gar nicht so schwer!
              <br />
              KI bringt auch Verantwortung mit sich. Im Kurs setzt du dich
              ebenso mit ethischen und gesellschaftlichen Auswirkungen von KI
              auseinander. Wir haben die <strong>
                brennendsten Themen
              </strong>{" "}
              für dich zusammengestellt. Werde KI-Experte!
              <br />
            </p>
          </div>
        </div>
      </div>
      <div className="frame-layout">
        <h1 className="womix__gradient infos-lehrende" id="infos-lehrende">
          Infos für Lehrende und Lernende
        </h1>
        <div className="infos-lehrende-content">
          <p>
            Die Kursinhalte eignen sich u.a. für den Schulunterricht, sowie den
            Einsatz in einer Vorlesung oder einer AG.
          </p>
          <p>
            In der Kategorie{" "}
            <a href="#woher-kommt">
              <span className="module1">Woher kommt KI?</span>
            </a>{" "}
            werden die wichtigsten Schritte in der Entwicklung von KI behandelt.
            Die Aufgaben aus dieser Kategorie eignen sich bestens für den
            Informatik-, NWT-, Technik, Ethik, Religions- und
            Geschichtsunterricht. Der Inhalt der Aufgaben fördert das
            Allgemeinwissen rund um das Thema Künstliche Intelligenz.
          </p>
          <p>
            In der Kategorie{" "}
            <a href="#wie-baut">
              <span className="module2">Wie baut man eine KI?</span>
            </a>{" "}
            lernt man schrittweise die Programmierung einer KI. Die Inhalte
            eignen sich im Schulunterricht v.a. für die Fächer Informatik und
            Technik.
          </p>
          <p>
            In der Kategorie{" "}
            <a href="#wie-geht">
              <span className="module3">Wie geht man mit KI um?</span>
            </a>{" "}
            setzt sich der Nutzer kritisch mit der Anwendung von KI in der
            Gesellschaft auseinander. Ethische Grundsatzfragen stehen hier im
            Vordergrund. Der Inhalt eignet sich bestens für den Ethik-,
            Religions-, Informatik-, Technik und NWT-Unterricht.
          </p>
          <p>
            Lehrende können einen oder mehrere{" "}
            <strong>virtuelle Klassenräume</strong> erstellen, Schüler:Innen
            aufnehmen und anschließend die Lernfortschritte verfolgen. Die
            Schüler:Innen bekommen einen Klassencode und einen Benutzernamen,
            mit denen sie sich beim Kurs anmelden können.
          </p>
          <p>
            Den vollständigen Syllabus des KI-Kurses können Sie hier als PDF
            herunterladen:
          </p>
          <div className="download-button-wrapper">
            <a
              className="button primary"
              href="/pdfs/Syllabus_2025.pdf"
              target="_blank"
            >
              {" "}
              <FontAwesomeIcon icon={faDownload} />
              Kursbroschüre
            </a>
          </div>
        </div>

        <h2 className="womix__gradient infos-lehrende" id="infos-lehrende">
          Sie haben Interesse an unserem Material?
        </h2>
        <div className="infos-lehrende-content">
          <p>
            Gerne können Sie unser Kursmaterial für eigene Zwecke (z.B.
            Unterricht) verwenden.
          </p>
          <p>
            Dabei gilt zu beachten, dass die Inhalte der Online-Kursseite
            (www.ki-kurs.org), soweit nicht abweichend gekennzeichnet, unter
            einer{" "}
            <strong>
              „Creative Common Namensnennung Share Alike“ Lizenz (CC BY SA 4.0)
            </strong>{" "}
            stehen.
          </p>

          <p>
            Das bedeutet, dass Sie das Kursmaterial in jedem Format kopieren und
            weiterverbreiten, verändern, neu zusammenstellen und für eigene
            Zwecke (z.B. Unterricht) inklusive kommerzielle Zwecke verwenden
            können, unter der Voraussetzung, dass Sie:
            <ul>
              <li>eine angemessene Quellenangabe machen</li>
              <li>einen Link zur Lizenz bereitstellen </li>
              <li>angeben, ob Änderungen vorgenommen wurden</li>
              <li>
                das Material unter der gleichen Lizenz wie das Original
                verbreiten (also CC BY SA 4.0).
              </li>
            </ul>
          </p>
        </div>
      </div>
      <div className="womix__image-wrapper">
        {" "}
        <img src="static/img/NeuroNetz_nur_ecke.png" alt="" className="" />{" "}
      </div>
    </section>
  );
}

export default Intro;
